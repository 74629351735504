import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Button = ({ destination, text, type, click }) => {
    return (
        <Link to={destination}>
            <button onClick={click} className={type}>{text}</button>
        </Link>
    )
}

Button.propTypes = {
    destination: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
    click: PropTypes.func,
}

export default Button