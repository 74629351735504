import React from 'react'
import { reduxForm } from 'redux-form'
import InfoBox from '../../infoBox/InfoBox'
import { connect } from 'react-redux'
import { sendDataCalculation } from '../../../actions'
import PropTypes from 'prop-types'

import './calculation.scss'

class Calculation extends React.Component {
    state = {
        costoTotale: '',
        creditoTotale: '',
    }

    componentDidMount() {
        const { values } = this.props.data.companyData

        const dipendenti = values.dipendenti
        const sedi = values.sedi
        const settoreServizi = values.settoreServizi
        const settoreProduzione = values.settoreProduzione
        const settoreCom = values.settoreCom
        const settoreWeb = values.settoreWeb

        const dipendentiInFormula = dipendenti * 1
        const sediInFormula = 1 + (sedi * 1.1) - sedi
        const serviziInFormula = (() => {
            if (settoreServizi && settoreServizi === true) {
                return 1.4
            } else if (!settoreServizi || settoreServizi === false) {
                return 1
            }
        })()
        const produzioneInFormula = (() => {
            if (settoreProduzione && settoreProduzione === true) {
                return 1.5
            } else if (!settoreProduzione || settoreProduzione === false) {
                return 1
            }
        })()
        const commercioInFormula = (() => {
            if (settoreCom && settoreCom === true) {
                return 1.5
            } else if (!settoreCom || settoreCom === false) {
                return 1
            }
        })()
        const webInFormula = (() => {
            if (settoreWeb && settoreWeb === true) {
                return 2
            } else if (!settoreWeb || settoreWeb === false) {
                return 1
            }
        })()

        const fatturatoAnnuoMinimo = (dipendentiInFormula * 35000) * sediInFormula * serviziInFormula * produzioneInFormula * commercioInFormula * webInFormula

        const marketing = fatturatoAnnuoMinimo * 0.015
        const vendite = fatturatoAnnuoMinimo * 0.008
        const acquisti = fatturatoAnnuoMinimo * 0.01
        const magazzino = fatturatoAnnuoMinimo * 0.012
        const produzione = fatturatoAnnuoMinimo * 0.013
        const hr = fatturatoAnnuoMinimo * 0.014

        const costoEffettivo = marketing + vendite + acquisti + magazzino + produzione + hr
        const costoConConsulenze = this.roundDecimails(costoEffettivo * 1.3, 2)
        const risorseProprie = (dipendenti / 100 * 25) * 35000
        const valoreCredito = costoConConsulenze + risorseProprie

        const costoTotale = costoConConsulenze + 5000
        const creditoTotale = valoreCredito / 2 + 5000

        const consulenze = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(costoConConsulenze - costoEffettivo)
        const marketingTotale = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(marketing + vendite)
        const acquistiTotale = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(acquisti)
        const magazzinoTotale = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(magazzino)
        const produzioneTotale = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(produzione)
        const hrTotale = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(hr)
        const certificazioneCredito = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(5000)
        const costoConConsulenzeTotale = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(costoTotale)
        const dipendentiRD = (this.roundDecimails((dipendenti / 100) * 25, 2)).toLocaleString()

        // outputs

        const costoTotaleToPrint = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(costoTotale)
        const creditoTotaleToPrint = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(creditoTotale)

        this.setState({
            costoTotale: costoTotaleToPrint,
            creditoTotale: creditoTotaleToPrint
        })

        const dataCalculation = {
            consulenze,
            marketingTotale,
            acquistiTotale,
            magazzinoTotale,
            produzioneTotale,
            hrTotale,
            certificazioneCredito,
            costoConConsulenzeTotale,
            dipendentiRD,
            creditoTotaleToPrint,
        }

        this.props.sendDataCalculation(dataCalculation)
    }

    roundDecimails(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    renderTitleCosto = () => {
        const {
            titleCostoOne,
            titleCostoTwo,
        } = this.props
        return (
            <React.Fragment>
                <div>{titleCostoOne}</div>
                <div>{titleCostoTwo}</div>
            </React.Fragment>
        )
    }

    renderTitleCredito = () => {
        const {
            titleCreditoOne,
            titleCreditoTwo,
        } = this.props
        return (
            <React.Fragment>
                <div>{titleCreditoOne}</div>
                <div>{titleCreditoTwo}</div>
            </React.Fragment>
        )
    }

    render() {
        const { handleSubmit, btnDettaglio } = this.props
        const { costoTotale, creditoTotale } = this.state
        return (
            <form className="cf-calculation" onSubmit={handleSubmit}>
                <InfoBox
                    type="info-box"
                    text={this.renderTitleCosto()}
                    value={costoTotale}
                />
                <InfoBox
                    type="info-box green"
                    text={this.renderTitleCredito()}
                    value={creditoTotale}
                />
                <div className="button">
                    <button
                        className="button-primary"
                    >
                        {btnDettaglio}
                    </button>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.form,
    }
}

Calculation.propTypes = {
    titleCostoOne: PropTypes.string,
    titleCostoTwo: PropTypes.string,
    titleCreditoOne: PropTypes.string,
    titleCreditoTwo: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    btnDettaglio: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, { sendDataCalculation })(reduxForm({
    form: 'companyData',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Calculation))