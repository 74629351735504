import React from 'react'
import PropTypes from 'prop-types'

import './details-box.scss'

const DetailsBox = ({
    text,
    labelConsulenza,
    labelMarketing,
    labelAcquisti,
    labelMagazzino,
    labelProduzione,
    labelRisorseUmane,
    labelCertificazione,
    labelTotale,
    valueConsulenza,
    valueMarketing,
    valueAcquisti,
    valueMagazzino,
    valueProduzione,
    valueHr,
    valueCertificazione,
    valueTotale,
}) => {
    return (
        <div className="details-box">
            <div className="text">{text}</div>
            <div className="details-row">
                <div>{labelConsulenza}</div>
                <div>{valueConsulenza}</div>
            </div>
            <div className="details-row">
                <div>{labelMarketing}</div>
                <div>{valueMarketing}</div>
            </div>
            <div className="details-row">
                <div>{labelAcquisti}</div>
                <div>{valueAcquisti}</div>
            </div>
            <div className="details-row">
                <div>{labelMagazzino}</div>
                <div>{valueMagazzino}</div>
            </div>
            <div className="details-row">
                <div>{labelProduzione}</div>
                <div>{valueProduzione}</div>
            </div>
            <div className="details-row">
                <div>{labelRisorseUmane}</div>
                <div>{valueHr}</div>
            </div>
            <div className="details-row">
                <div>{labelCertificazione}</div>
                <div>{valueCertificazione}</div>
            </div>
            <div className="details-row">
                <div className="total-text">{labelTotale}</div>
                <div className="total-number">{valueTotale}</div>
            </div>
        </div>
    )
}

DetailsBox.propTypes = {
    text: PropTypes.object,
    labelConsulenza: PropTypes.string,
    labelMarketing: PropTypes.string,
    labelAcquisti: PropTypes.string,
    labelMagazzino: PropTypes.string,
    labelProduzione: PropTypes.string,
    labelRisorseUmane: PropTypes.string,
    labelCertificazione: PropTypes.string,
    labelTotale: PropTypes.string,
    valueConsulenza: PropTypes.string,
    valueMarketing: PropTypes.string,
    valueAcquisti: PropTypes.string,
    valueMagazzino: PropTypes.string,
    valueProduzione: PropTypes.string,
    valueHr: PropTypes.string,
    valueCertificazione: PropTypes.string,
    valueTotale: PropTypes.string,
}

export default DetailsBox