import React from 'react'
import Header from '../../components/header/Header'
import Button from '../../components/button/Button'
import ButtonScroll from '../../components/buttonScroll/ButtonScroll'
import scrollToComponent from 'react-scroll-to-component'
import { ReactComponent as Dubt } from '../../assets/icons/dubt.svg'
import { ReactComponent as Info } from '../../assets/icons/information.svg'
import {
    title,
    subTitle,
    subTitleTwo,
    subTitleThree,
    btnStartText,
    btnScroll,
    btnScrollText,
    infoTextOne,
    infoTextTwo,
    bodyTextOne,
    bodyTextTwo,
    bodyTextThree
} from '../../cfg/constants'

import './home.scss'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-138523609-1')
ReactGA.pageview(window.location.pathname + window.location.search)

class Home extends React.Component {
    onClickScroll = () => {
        scrollToComponent(this.Content, { offset: 0, align: 'top', duration: 1200 })
        ReactGA.event({
            category: 'Click',
            action: 'Button click',
            label: 'Scopri di più'
        });
    }

    goToFormTop = () => {
        ReactGA.event({
            category: 'Click',
            action: 'Button click',
            label: 'Inizia (alto)'
        });
    }

    goToFormBottom = () => {
        ReactGA.event({
            category: 'Click',
            action: 'Button click',
            label: 'Inizia (basso)'
        });
    }

    render() {
        return (
            <div className="home-container">

                <div className="header-container text-center">
                    <div className="container">

                        <div className="row">
                            <div className="col-12">
                                <Header
                                    title={title}
                                    subTitle={subTitle}
                                    subTitleTwo={subTitleTwo}
                                    subTitleThreeOne={subTitleThree[0]}
                                    subTitleThreeTwo={subTitleThree[1]}
                                    subTitleThreeThree={subTitleThree[2]}
                                />
                            </div>
                        </div>

                        <div className="call-to-actions">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 d-flex">
                                    <div className="button">
                                        <Button
                                            text={btnStartText}
                                            destination="/form"
                                            type="button-primary"
                                            click={this.goToFormTop}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 d-flex">
                                    <div className="desc-cta">
                                        {btnScroll}
                                    </div>
                                    <div className="button">
                                        <ButtonScroll
                                            text={btnScrollText}
                                            type="button-secondary"
                                            onClick={this.onClickScroll}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="main-content-container" ref={(div) => { this.Content = div }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <div className="info-box-home">
                                    <div className="icon-container">
                                        <Dubt />
                                    </div>
                                    <span className="green-text"><strong>{infoTextOne[0]}</strong></span><br />
                                    {infoTextOne[1]}
                                    <br /><br />
                                    <span className="green-text"><strong>{infoTextTwo[0]}</strong></span><br />
                                    {infoTextTwo[1]} <br />
                                    {infoTextTwo[2]}<br />
                                    {infoTextTwo[3]}<br />
                                    {infoTextTwo[4]}<br /><br />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <div className="padding-box">
                                    <div className="icon-container">
                                        <Info />
                                    </div>
                                    <span className="green-text"><strong>{bodyTextOne[0]}</strong></span><br />
                                    {bodyTextOne[1]}
                                    <br /><br />
                                    <span className="green-text"><strong>{bodyTextTwo[0]}</strong></span><br />
                                    {bodyTextTwo[1]}
                                    <br /><br />
                                    {bodyTextTwo[2]}
                                    <br /><br />
                                    <span className="green-text"><strong>{bodyTextThree[0]}</strong></span><br />
                                    {bodyTextThree[1]}
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-12">
                                <div className="button">
                                    <Button
                                        text={btnStartText}
                                        destination="/form"
                                        type="button-primary"
                                        click={this.goToFormBottom}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Home