import { SIGN_IN, SIGN_OUT, SEND_DATA_CALCULATION } from './types'

export const signIn = (userProfile) => {
    return {
        type: SIGN_IN,
        payload: userProfile,
    }
}

export const signOut = () => {
    return {
        type: SIGN_OUT,
    }
}

export const sendDataCalculation = (dataCalculation) => {
    return {
        type: SEND_DATA_CALCULATION,
        payload: dataCalculation,
    }
}