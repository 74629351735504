import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { errorsText } from '../../../cfg/constants'
import PropTypes from 'prop-types'

import './data.scss'

class Data extends React.Component {

    renderError({ error, touched }) {
        if (touched && error) {
            return <div className="error">{error}</div>
        }
    }

    renderInputNumber = ({ input, label, max, meta }) => {
        return (
            <div className="input-container">
                <input {...input} type="number" placeholder={label} min="1" max={max} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        );
    };

    renderInputCheckbox = ({ input, label, meta }) => {
        return (
            <div className="checkbox-container">
                <label className="checkbox">
                    <input {...input} type="checkbox" />
                    <div className="label-checkbox">{label}</div>
                    <span className="checkmark"></span>
                </label>
            </div>
        );
    };


    render() {
        const {
            handleSubmit,
            invalid,
            submitting,
            labelDipendenti,
            labelSedi,
            labelSettore,
            labelSettoreServizi,
            labelSettoreCom,
            labelSettoreProduzione,
            labelSettoreWeb,
            btnCalcola
        } = this.props
        return (
            <form className="cf-data" onSubmit={handleSubmit}>
                <div className="first-line">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="dipendenti"
                                    component={this.renderInputNumber}
                                    label={labelDipendenti}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="sedi"
                                    component={this.renderInputNumber}
                                    label={labelSedi}
                                    max="10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-line">
                    <div className="container-fluid p-0">
                        <div className="label">
                            <div className="row">
                                <div className="col-12">{labelSettore}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="settoreServizi"
                                    component={this.renderInputCheckbox}
                                    type="checkbox"
                                    label={labelSettoreServizi}
                                />
                                <Field
                                    name="settoreProduzione"
                                    component={this.renderInputCheckbox}
                                    type="checkbox"
                                    label={labelSettoreProduzione}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="settoreCom"
                                    component={this.renderInputCheckbox}
                                    type="checkbox"
                                    label={labelSettoreCom}
                                />
                                <Field
                                    name="settoreWeb"
                                    component={this.renderInputCheckbox}
                                    type="checkbox"
                                    label={labelSettoreWeb}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button">
                    <button
                        className="button-primary"
                        disabled={invalid || submitting}
                    >
                        {btnCalcola}
                    </button>
                </div>
            </form>
        )
    }
}

const validate = (formValues) => {
    const errors = {}

    if (!formValues.dipendenti) {
        errors.dipendenti = errorsText.errorDipendenti
    } else if (formValues.dipendenti < 1) {
        errors.dipendenti = errorsText.errorDipendentiMin
    }

    if (!formValues.sedi) {
        errors.sedi = errorsText.errorSedi
    } else if (formValues.sedi < 1) {
        errors.sedi = errorsText.errorSediMin
    } else if (formValues.sedi > 10) {
        errors.sedi = errorsText.errorSediMax
    }

    if (!formValues.settoreServizi && !formValues.settoreProduzione && !formValues.settoreCom && !formValues.settoreWeb) {
        errors.settoreProduzione = 'errore'
    }

    return errors
}


Data.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    labelDipendenti: PropTypes.string,
    labelSedi: PropTypes.string,
    labelSettore: PropTypes.string,
    labelSettoreServizi: PropTypes.string,
    labelSettoreCom: PropTypes.string,
    labelSettoreProduzione: PropTypes.string,
    labelSettoreWeb: PropTypes.string,
    btnCalcola: PropTypes.string.isRequired,
}


export default reduxForm({
    form: 'companyData',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(Data)