import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import './modal.scss'

class Modal extends React.Component {
    state = { modal: null }

    closeModal = async () => {
        await this.setState({ modal: false })
        this.props.hideModal(this.state.modal)
    }

    render() {
        const { modalText } = this.props
        return ReactDOM.createPortal(
            <div onClick={this.closeModal} className="modal-box">
                <div onClick={(e) => e.stopPropagation()} className="modal-content">
                    <span onClick={this.closeModal} className="close">&times;</span>
                    <p className="text">{modalText}</p>
                </div>
            </div>,
            document.querySelector('#modal')
        )
    }
}

Modal.propTypes = {
    modalText: PropTypes.string,
}

export default Modal