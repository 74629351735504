import { SEND_DATA_CALCULATION } from '../actions/types'

const INITIAL_STATE = {
    dataCalculation: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_DATA_CALCULATION:
            return { ...state, dataCalculation: action.payload }
        default:
            return state
    }
}