import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { errorsText } from '../../../cfg/constants'
import PropTypes from 'prop-types'

import './info.scss'

class Info extends React.Component {

    renderError({ error, touched }) {
        if (touched && error) {
            return <div className="error">{error}</div>
        }
    }

    renderInputField = ({ input, label, meta }) => {
        return (
            <div className="input-container">
                <input {...input} type="text" placeholder={label} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        );
    };

    renderInputCheckbox = ({ input, label, meta, privacy }) => {
        return (
            <div className="checkbox-container">
                <label className="checkbox">
                    <input {...input} type="checkbox" />
                    <span>{label}</span>
                    <span className="checkmark"></span>
                </label>
                <span className="privacy-link" onClick={this.openLink}>{privacy}</span>
                {this.renderError(meta)}
            </div>
        );
    };

    openLink = () => {
        window.open(process.env.REACT_APP_PDF_PRIVACY, '_blank')
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.userData.isSignedIn !== nextProps.userData.isSignedIn && nextProps.userData.isSignedIn === true) {
            if (nextProps.userData.userProfile.accessToken) {
                const name = nextProps.userData.userProfile.name.split(' ');
                this.props.change("nome", name[0])
                this.props.change("cognome", name[1])
                this.props.change("email", nextProps.userData.userProfile.email)
            } else {
                this.props.change("nome", nextProps.userData.userProfile.ofa)
                this.props.change("cognome", nextProps.userData.userProfile.wea)
                this.props.change("email", nextProps.userData.userProfile.U3)
            }
        }
    }

    onSubmit = (formValues) => {
        this.props.onSubmit(formValues)
    }

    render() {
        const {
            handleSubmit,
            invalid,
            submitting,
            labelNome,
            labelCognome,
            labelEmail,
            labelAzienda,
            labelCitta,
            labelPrivacyOne,
            labelPrivacyTwo,
            btnVisualizza,
        } = this.props
        return (
            <form className="cf-info" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="container">
                    <div className="first-line">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="nome"
                                    component={this.renderInputField}
                                    label={labelNome}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="cognome"
                                    component={this.renderInputField}
                                    label={labelCognome}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="second-line">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="email"
                                    component={this.renderInputField}
                                    label={labelEmail}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Field
                                    name="azienda"
                                    component={this.renderInputField}
                                    label={labelAzienda}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="third-line">
                        <div className="row">
                            <div className="col-12">
                                <Field
                                    name="citta"
                                    component={this.renderInputField}
                                    label={labelCitta}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="fourth-line">
                        <div className="row">
                            <div className="col-12">
                                <Field
                                    name="privacy"
                                    component={this.renderInputCheckbox}
                                    type="checkbox"
                                    label={labelPrivacyOne}
                                    privacy={labelPrivacyTwo}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="button">
                    <button
                        className="button-primary"
                        disabled={invalid || submitting}
                    >
                        {btnVisualizza}
                    </button>
                </div>

            </form>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if (!formValues.nome) {
        errors.nome = errorsText.errorNome
    }

    if (!formValues.cognome) {
        errors.cognome = errorsText.errorCognome
    }

    if (!formValues.email) {
        errors.email = errorsText.errorEmailOne
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = errorsText.errorEmailTwo
    }

    if (!formValues.privacy) {
        errors.privacy = errorsText.errorCheckPrivacy
    }

    return errors
}


const mapStateToProps = (state) => {
    return {
        userData: state.auth
    }
}


Info.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    labelNome: PropTypes.string,
    labelCognome: PropTypes.string,
    labelEmail: PropTypes.string,
    labelAzienda: PropTypes.string,
    labelCitta: PropTypes.string,
    labelPrivacyOne: PropTypes.string,
    labelPrivacyTwo: PropTypes.string,
    btnVisualizza: PropTypes.string.isRequired,
}


export default connect(mapStateToProps)(reduxForm({
    form: 'companyData',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(Info))
