import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import Home from '../routes/home/Home'
import WizardForm from '../routes/wizardForm/WizardForm'
import history from '../history'

import 'normalize.css'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../style/style.scss'

history.listen(_ => {
    window.scrollTo(0, 0)
})

const App = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/form" exact component={WizardForm} />
            </Switch>
        </Router>
    )
}

export default App