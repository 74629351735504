import React from 'react'
import Data from '../../components/form/data/Data'
import Calculation from '../../components/form/calculation/Calculation'
import Info from '../../components/form/info/Info'
import Details from '../../components/form/details/Details'
import GoogleAuth from '../../components/google/GoogleAuth'
import FacebookAuth from '../../components/facebook/FacebookAuth'
import Loader from '../../components/loader/Loader'
import { connect } from 'react-redux'
import axios from 'axios'
import { dataText, calculationText, infoText, detailsText } from '../../cfg/constants'
import './wizard-form.scss'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-138523609-1')

class WizardForm extends React.Component {
	constructor(props) {
		super(props)
		this.nextPage = this.nextPage.bind(this)
		this.handleFormSubmit = this.handleFormSubmit.bind(this)
		this.state = {
			page: 1,
		}
	}

	componentDidUpdate() {
		window.scrollTo(0, 0)
	}

	nextPage() {
		this.setState({ page: this.state.page + 1 })
	}

	async handleFormSubmit(formValues) {
		const { dataCalculation } = this.props.calculationData

		const consulenze = dataCalculation.consulenze
		const marketingTotale = dataCalculation.marketingTotale
		const acquistiTotale = dataCalculation.acquistiTotale
		const magazzinoTotale = dataCalculation.magazzinoTotale
		const produzioneTotale = dataCalculation.produzioneTotale
		const hrTotale = dataCalculation.hrTotale
		const certificazioneCredito = dataCalculation.certificazioneCredito
		const costoConConsulenzeTotale = dataCalculation.costoConConsulenzeTotale
		const dipendentiRD = dataCalculation.dipendentiRD
		const creditoTotale = dataCalculation.creditoTotaleToPrint

		const dipendenti = formValues.dipendenti
		const sedi = formValues.sedi
		const settoreCom = (() => {
			if (formValues.settoreCom && formValues.settoreCom === true) {
				return formValues.settoreCom
			} else {
				return 0
			}
		})()
		const settoreProduzione = (() => {
			if (formValues.settoreProduzione && formValues.settoreProduzione === true) {
				return formValues.settoreProduzione
			} else {
				return 0
			}
		})()
		const settoreServizi = (() => {
			if (formValues.settoreServizi && formValues.settoreServizi === true) {
				return formValues.settoreServizi
			} else {
				return 0
			}
		})()
		const settoreWeb = (() => {
			if (formValues.settoreWeb && formValues.settoreWeb === true) {
				return formValues.settoreWeb
			} else {
				return 0
			}
		})()
		const nome = formValues.nome
		const cognome = formValues.cognome
		const email = formValues.email
		const azienda = (() => {
			if (formValues.azienda) {
				return formValues.azienda
			} else {
				return 'vuoto'
			}
		})()
		const citta = (() => {
			if (formValues.citta) {
				return formValues.citta
			} else {
				return 'vuoto'
			}
		})()

		const detail = true
		const btnContact = false

		const response = await axios.post(process.env.REACT_APP_API_PATH, {
			detail,
			btnContact,
			consulenze,
			marketingTotale,
			acquistiTotale,
			magazzinoTotale,
			produzioneTotale,
			hrTotale,
			certificazioneCredito,
			costoConConsulenzeTotale,
			dipendentiRD,
			creditoTotale,
			nome,
			cognome,
			email,
			azienda,
			citta,
			dipendenti,
			sedi,
			settoreCom,
			settoreProduzione,
			settoreServizi,
			settoreWeb
		})
		if (response.data.sent === true) {
			this.setState({ page: this.state.page + 1 })
			setTimeout(function () {
				this.setState({ page: this.state.page + 1 })
			}.bind(this), 3000);
		} else if (response.data.msg === 'fail') {
			alert('Ops, si è verificato qualche problema, riprova per favore')
		}
	}

	renderLoginButtons = () => {
		const { userData } = this.props
		if (userData.isSignedIn) {
			if (userData.userProfile.accessToken) {
				return (
					<div className="or-row">
						{infoText.welcome}
					</div>
				)
			} else {
				return (
					<div className="social-row">
						<div className="row">
							<div className="col-12">
								<GoogleAuth logoutGoogle={infoText.logoutGoogle} />
							</div>
						</div>
					</div>
				)
			}
		} else {
			return (
				<div>
					<div className="social-row">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<GoogleAuth loginGoogle={infoText.loginGoogle} />
							</div>
							<div className="col-sm-12 col-md-6">
								<FacebookAuth loginFacebook={infoText.loginFacebook} />
							</div>
						</div>
					</div>
					<div className="or-row">
						{infoText.or}
					</div>
				</div>
			)
		}
	}

	render() {
		const { page } = this.state
		if (page === 1) { ReactGA.pageview(window.location.pathname + page + window.location.search) }
		else if (page === 2) { ReactGA.pageview(window.location.pathname + page + window.location.search) }
		else if (page === 3) { ReactGA.pageview(window.location.pathname + page + window.location.search) }
		else if (page >= 5) { ReactGA.pageview(window.location.pathname + page + window.location.search) }
		return (
			<div className="form-container">
				{page === 1 && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<Data
									onSubmit={this.nextPage}
									labelDipendenti={dataText.labelDipendenti}
									labelSedi={dataText.labelSedi}
									labelSettore={dataText.labelSettore}
									labelSettoreServizi={dataText.labelSettoreServizi}
									labelSettoreCom={dataText.labelSettoreCom}
									labelSettoreProduzione={dataText.labelSettoreProduzione}
									labelSettoreWeb={dataText.labelSettoreWeb}
									btnCalcola={dataText.btnCalcola}
								/>
							</div>
						</div>
					</div>
				)}
				{page === 2 && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<Calculation
									onSubmit={this.nextPage}
									titleCostoOne={calculationText.titleCostoOne}
									titleCostoTwo={calculationText.titleCostoTwo}
									titleCreditoOne={calculationText.titleCreditoOne}
									titleCreditoTwo={calculationText.titleCreditoTwo}
									btnDettaglio={calculationText.btnDettaglio}
								/>
							</div>
						</div>
					</div>
				)}
				{page === 3 && (
					<div className="container">
						{this.renderLoginButtons()}
						<div className="row">
							<div className="col-12">
								<Info
									onSubmit={this.handleFormSubmit}
									labelNome={infoText.labelNome}
									labelCognome={infoText.labelCognome}
									labelEmail={infoText.labelEmail}
									labelAzienda={infoText.labelAzienda}
									labelCitta={infoText.labelCitta}
									labelPrivacyOne={infoText.labelPrivacyOne}
									labelPrivacyTwo={infoText.labelPrivacyTwo}
									btnVisualizza={infoText.btnVisualizza}
								/>
							</div>
						</div>
					</div>
				)}
				{page === 4 && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<Loader />
							</div>
						</div>
					</div>
				)}
				{page >= 5 && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<Details
									titleProcessiOne={detailsText.titleProcessiOne}
									titleProcessiTwo={detailsText.titleProcessiTwo}
									labelConsulenza={detailsText.labelConsulenza}
									labelMarketing={detailsText.labelMarketing}
									labelAcquisti={detailsText.labelAcquisti}
									labelMagazzino={detailsText.labelMagazzino}
									labelProduzione={detailsText.labelProduzione}
									labelRisorseUmane={detailsText.labelRisorseUmane}
									labelCertificazione={detailsText.labelCertificazione}
									labelTotale={detailsText.labelTotale}
									titlePersonaleOne={detailsText.titlePersonaleOne}
									titlePersonaleTwo={detailsText.titlePersonaleTwo}
									titlePersonaleThree={detailsText.titlePersonaleThree}
									titleCreditoOne={calculationText.titleCreditoOne}
									titleCreditoTwo={calculationText.titleCreditoTwo}
									btnContact={detailsText.btnContact}
									modalText={detailsText.modalText}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userData: state.auth,
		calculationData: state.data
	}
}

export default connect(mapStateToProps)(WizardForm)