import React from 'react'
import { ReactComponent as Arrow } from '../../assets/icons/angle-down-solid.svg'
import PropTypes from 'prop-types'

class ButtonScroll extends React.Component {
    render() {
        const { text, type, onClick } = this.props
        return (
            <button className={type} onClick={onClick}><span>{text}</span><Arrow /></button>
        )
    }
}

ButtonScroll.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

export default ButtonScroll