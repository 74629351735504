import React from 'react'
import FacebookLogin from 'react-facebook-login'
import { connect } from 'react-redux'
import { signIn, signOut } from '../../actions'
import PropTypes from 'prop-types'

import './facebook-auth.scss'

class FacebookAuth extends React.Component {
    state = {
        isLoggedIn: false,
        userID: '',
        name: '',
        email: '',
        picture: ''
    }

    responseFacebook = (response) => {
        this.props.signIn(response)
    }

    render() {
        const { loginFacebook } = this.props

        let fbContent

        if (this.state.isLoggedIn) {
            fbContent = null
        } else {
            fbContent = (
                <FacebookLogin
                    appId={process.env.REACT_APP_ID_CLIENT_FACEBOOK}
                    autoLoad={false}
                    fields='name,email,picture'
                    callback={this.responseFacebook}
                    cssClass='facebook-btn'
                    icon='fa-facebook'
                    textButton={loginFacebook}
                    disableMobileRedirect={true}
                />
            )
        }

        return (
            <div className="social-btn">
                {fbContent}
            </div>
        )
    }
}


FacebookAuth.propTypes = {
    loginFacebook: PropTypes.string.isRequired,
}


export default connect(null, { signIn, signOut })(FacebookAuth)