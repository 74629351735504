// HOME
const title = 'Innovare gratis è possibile!'
const subTitle = 'Effettua una simulazione per sapere quanto la tua azienda dovrebbe investire in ricerca e sviluppo per affrontare le sfide del mercato per i prossimi 10 anni'
const subTitleTwo = 'Non farti trovare impreparato!'
const subTitleThree = ['Scopri quanto potrebbe ', 'ricevere in credito di imposta', ' o altre misure oggi disponibili la tua azienda, potresti accorgerti che non è una fantasia ma una grande opportunità']
const btnStartText = 'inizia'
const btnScroll = 'Non sei ancora convinto?'
const btnScrollText = 'scopri di più'
const infoTextOne = [
    'Prima vi spiego cos\'è e perché ...',
    'Si tratta di una applicazione web capace di calcolare sulla base di pochi parametri il valore economico necessario da investire in ricerca e sviluppo per innovare i processi aziendali e capace di identificare la spesa ammissibile al credito di imposta (ovvero riesce a calcolare quanto puoi recuperare dell\'investimento fatto attraverso questa misura messa a disposizione dal MISE).',
]
const infoTextTwo = [
    'Il target',
    '- Amministratori d\'azienda',
    '- Professionisti esperti di Digital transformation',
    '- Professionisti e consulenti commerciali e/o finanziari',
    '- Direttori tecnici di azienda o più in generale chiunque abbia a che fare con questi soggetti e che si interessi di investimenti o tecnologie.',
]
const bodyTextOne = [
    'Credito d’imposta R&S',
    'E’ una misura dedicata alle imprese con sede stabile in Italia utile a stimolare la spesa in Ricerca e Sviluppo per innovare processi e prodotti per garantire la competitività futura delle imprese.',
]
const bodyTextTwo = [
    'Quali vantaggi',
    'Credito d’imposta del 50% fino a un massimo annuale di 20 milioni di €/anno. Il crediti d’imposta può essere utilizzato, anche in caso di perdite, a copertura di un ampio insieme di imposte e contributi.',
    'Sono agevolabili tutte le spese relative a ricerca fondamentale, ricerca industriale e sviluppo sperimentale: costi per personale altamente qualificato e tecnico, contratti di ricerca con università, enti di ricerca, imprese, start up e PMI innovative.',
]
const bodyTextThree = [
    'Il beneficio è cumulabile con:',
    'Superammortamento, Iperammortamento, Nuova Sabatini, Patent Box, Incentivi alla patrimonilizzazione delle imprese (ACE), Incentivi agli investimenti in Start up e PMI innovative, Fondoo Centrale di Garanzia.',
]

export {
    title,
    subTitle,
    subTitleTwo,
    subTitleThree,
    btnStartText,
    btnScroll,
    btnScrollText,
    infoTextOne,
    infoTextTwo,
    bodyTextOne,
    bodyTextTwo,
    bodyTextThree,
}




// WIZARD FORM

// DATA

const dataText = {
    labelDipendenti: 'N. dipendenti *',
    labelSedi: 'N. sedi *',
    labelSettore: 'Settore: *',
    labelSettoreServizi: 'Servizi / Commesse / Consulenza',
    labelSettoreCom: 'Commercializzazione',
    labelSettoreProduzione: 'Produzione',
    labelSettoreWeb: 'Web / e-Commerce',
    btnCalcola: 'calcola',
}

export { dataText }


const calculationText = {
    titleCostoOne: 'Costo totale del progetto di',
    titleCostoTwo: 'trasferimento tecnologico ed innovazione',
    titleCreditoOne: 'Il credito che potrai ricevere e scalare',
    titleCreditoTwo: 'dai tuoi F24 è il seguente',
    btnDettaglio: 'vai al dettaglio',
}

export { calculationText }


const infoText = {
    loginGoogle: 'log in with google',
    logoutGoogle: 'log out',
    loginFacebook: 'log in with facebook',
    or: 'oppure',
    welcome: 'benvenuto',
    labelNome: 'Nome *',
    labelCognome: 'Cognome *',
    labelEmail: 'Email *',
    labelAzienda: 'Azienda',
    labelCitta: 'Città / CAP / Provincia',
    labelPrivacyOne: 'dichiaro di avere letto e di accettare ',
    labelPrivacyTwo: ' i termini sulla privacy',
    btnVisualizza: 'visualizza',
}

export { infoText }


const detailsText = {
    titleProcessiOne: 'I processi da innovare e le rispettive',
    titleProcessiTwo: 'quote di investimento',
    labelConsulenza: 'consulenza',
    labelMarketing: 'crm / vendite / marketing',
    labelAcquisti: 'gestione acquisti',
    labelMagazzino: 'magazzino / logistica',
    labelProduzione: 'produzione',
    labelRisorseUmane: 'gestione risorse umane',
    labelCertificazione: 'certificazione del credito',
    labelTotale: 'per un totale di',
    titlePersonaleOne: 'Alle attività di R&D della tua azienda',
    titlePersonaleTwo: 'potranno partecipare il seguente numero',
    titlePersonaleThree: 'di dipendenti',
    btnContact: 'richiedi contatto',
    modalText: 'Grazie, ti ricontatteremo prima possibile',
}

export { detailsText }


const errorsText = {
    errorDipendenti: 'Aggiungi il numero dei dipendenti',
    errorDipendentiMin: 'Inserisci un numero maggiore o uguale ad 1',
    errorSedi: 'Aggiungi il numero delle sedi',
    errorSediMin: 'Inserisci un numero maggiore o uguale ad 1',
    errorSediMax: 'Inserisci un numero minore o uguale a 10',
    errorNome: 'Aggiungi il nome',
    errorCognome: 'Aggiungi il cognome',
    errorEmailOne: 'Aggiungi l\'email',
    errorEmailTwo: 'Email non valida',
    errorCheckPrivacy: 'Seleziona la checkbox prima di continuare',
}

export { errorsText }