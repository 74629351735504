import React from 'react'
import { connect } from 'react-redux'
import DetailsBox from '../../detailsBox/DetailsBox'
import InfoBox from '../../infoBox/InfoBox'
import ButtonContact from '../../buttonContact/ButtonContact';
import Modal from '../../modal/Modal'
import PropTypes from 'prop-types'

import './details.scss'

class Details extends React.Component {
    state = { visible: false }

    renderTitleProcessi = () => {
        const {
            titleProcessiOne,
            titleProcessiTwo,
        } = this.props
        return (
            <React.Fragment>
                <div>{titleProcessiOne}</div>
                <div>{titleProcessiTwo}</div>
            </React.Fragment>
        )
    }

    renderTitlePersonale = () => {
        const {
            titlePersonaleOne,
            titlePersonaleTwo,
            titlePersonaleThree,
        } = this.props
        return (
            <React.Fragment>
                <div>{titlePersonaleOne}</div>
                <div>{titlePersonaleTwo}</div>
                <div>{titlePersonaleThree}</div>
            </React.Fragment>
        )
    }


    renderTitleCredito = () => {
        const {
            titleCreditoOne,
            titleCreditoTwo,
        } = this.props
        return (
            <React.Fragment>
                <div>{titleCreditoOne}</div>
                <div>{titleCreditoTwo}</div>
            </React.Fragment>
        )
    }

    showModal = (modal) => {
        this.setState({ visible: modal })
    }

    hideModal = (modal) => {
        this.setState({ visible: modal })
    }

    render() {
        const { visible } = this.state
        const { dataCalculation } = this.props.data
        const {
            labelConsulenza,
            labelMarketing,
            labelAcquisti,
            labelMagazzino,
            labelProduzione,
            labelRisorseUmane,
            labelCertificazione,
            labelTotale,
            btnContact,
            modalText,
        } = this.props
        return (
            <React.Fragment>
                <DetailsBox
                    text={this.renderTitleProcessi()}
                    labelConsulenza={labelConsulenza}
                    labelMarketing={labelMarketing}
                    labelAcquisti={labelAcquisti}
                    labelMagazzino={labelMagazzino}
                    labelProduzione={labelProduzione}
                    labelRisorseUmane={labelRisorseUmane}
                    labelCertificazione={labelCertificazione}
                    labelTotale={labelTotale}
                    valueConsulenza={dataCalculation.consulenze}
                    valueMarketing={dataCalculation.marketingTotale}
                    valueAcquisti={dataCalculation.acquistiTotale}
                    valueMagazzino={dataCalculation.magazzinoTotale}
                    valueProduzione={dataCalculation.produzioneTotale}
                    valueHr={dataCalculation.hrTotale}
                    valueCertificazione={dataCalculation.certificazioneCredito}
                    valueTotale={dataCalculation.costoConConsulenzeTotale}
                />
                <InfoBox
                    type="info-box green"
                    text={this.renderTitleCredito()}
                    value={dataCalculation.creditoTotaleToPrint}
                />
                <InfoBox
                    type="info-box"
                    text={this.renderTitlePersonale()}
                    value={dataCalculation.dipendentiRD}
                />
                <div className="btn-contact">
                    <ButtonContact
                        btnContact={btnContact}
                        showModal={this.showModal}
                    />
                </div>
                {visible === true && (
                    <Modal
                        hideModal={this.hideModal}
                        modalText={modalText}
                    />
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.data
    }
}


Details.propTypes = {
    titleProcessiOne: PropTypes.string,
    titleProcessiTwo: PropTypes.string,
    titlePersonaleOne: PropTypes.string,
    titlePersonaleTwo: PropTypes.string,
    titlePersonaleThree: PropTypes.string,
    labelConsulenza: PropTypes.string,
    labelMarketing: PropTypes.string,
    labelAcquisti: PropTypes.string,
    labelMagazzino: PropTypes.string,
    labelProduzione: PropTypes.string,
    labelRisorseUmane: PropTypes.string,
    labelCertificazione: PropTypes.string,
    labelTotale: PropTypes.string,
    titleCreditoOne: PropTypes.string,
    titleCreditoTwo: PropTypes.string,
    btnContact: PropTypes.string,
    modalText: PropTypes.string,
}


export default connect(mapStateToProps)(Details)
