import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Pig } from '../../assets/icons/pig-icon.svg'

import './header.scss'

const Header = ({
    title,
    subTitle,
    subTitleTwo,
    subTitleThreeOne,
    subTitleThreeTwo,
    subTitleThreeThree,
}) => {
    return (
        <div className="header">
            <h1>
                <div>
                    <span className="green-highlight black-text">
                        <strong>{title}</strong>
                    </span>
                </div>
                <br />
                <div>{subTitle}</div>
                <br />
                <div>
                    <strong>{subTitleTwo}</strong>
                </div>
                <br />
            </h1>
            <div className="header-icon">
                <Pig />
            </div>
            <br />
            <div>
                {subTitleThreeOne}
                <span className="green-highlight">{subTitleThreeTwo}</span>
                {subTitleThreeThree}
            </div>
        </div>
    )
}


Header.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleTwo: PropTypes.string,
    subTitleThreeOne: PropTypes.string,
    subTitleThreeTwo: PropTypes.string,
    subTitleThreeThree: PropTypes.string,
}

export default Header