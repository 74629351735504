import React from 'react'
import PropTypes from 'prop-types'

import './info-box.scss'

const InfoBox = ({ type, text, value }) => {
    return (
        <div className={type}>
            <div className="text">{text}</div>
            <div className="value">{value}</div>
        </div>
    )
}

InfoBox.propTypes = {
    type: PropTypes.string,
    text: PropTypes.object,
    value: PropTypes.string,
}



export default InfoBox