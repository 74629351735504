import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-138523609-1')

class ButtonContact extends React.Component {

    state = { modal: false }

    onButtonClick = async () => {
        const { values } = this.props.formData.companyData

        const nome = values.nome
        const cognome = values.cognome
        const email = values.email

        const detail = false
        const btnContact = true

        const response = await axios.post(process.env.REACT_APP_API_PATH, {
            nome,
            cognome,
            email,
            detail,
            btnContact
        })
        if (response.data.sent === true) {
            this.setState({ modal: true })
            this.props.showModal(this.state.modal)

            ReactGA.event({
                category: 'Click',
                action: 'Click richiesta di contatto',
                label: 'Richiesta di contatto',
                value: 1
            });

        } else if (response.data.msg === 'fail') {
            alert('Ops, si è verificato qualche problema, riprova per favore')
        }
    }

    render() {
        const { btnContact } = this.props
        return (
            <button
                className="button-primary"
                onClick={this.onButtonClick}
            >
                {btnContact}
            </button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        formData: state.form,
    }
}

ButtonContact.propTypes = {
    btnContact: PropTypes.string,
}

export default connect(mapStateToProps)(ButtonContact)