import React from 'react'
import { ReactComponent as LoadIcon } from '../../assets/icons/hourglass-half-solid.svg'
import './loader.scss'

const Loader = () => {
    return (
        <div className="loader-container">
            <LoadIcon />
        </div>
    )
}

export default Loader